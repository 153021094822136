<template>
  <div class="hero">
      <div class="hero-text">
        <div class="hero-top">
          <img
            @click="$emit('switchScreen')"
            :src="'/' + logo"
            @mouseover="isMouseover = true"
            @mouseleave="isMouseover = false"
            alt="logo"
            style="max-width: 10em; height: auto; color: red !important;"
          />
          <div class="mt-3">
            <ul>
              <li><a @click="$emit('switchScreen', 0)" href="#">About</a></li>
              <li><a @click="$emit('switchScreen', 1)" href="#">4D Corridors</a></li>
              <li><a @click="$emit('switchScreen', 2)" href="#">4D Corridors Prospects</a></li>
              <li><a @click="$emit('switchScreen', 3)" href="#">Disruptive Technologies</a></li>
              <li><a @click="$emit('switchScreen', 4)" href="#">Focus on Results</a></li>
              <li><a @click="$emit('switchScreen', 5)" href="#">A Clear Timeline</a></li>
              <li><a @click="$emit('switchScreen', 6)" href="#">Join 4D</a></li>
            </ul>
            <div class="pt-4">
              <a id="gotoDigitalGreen" target="_blank" href="https://green.4dcorridor.org">Go to Digital Green Corridor</a>
            </div>
          </div>
        </div>
        <Footer />
        <div class="hero-footer text-muted">
      </div>
        <div class="hero-footer text-muted">
            Copyright &copy; 2021 4D Agenda. All rights reserved. Privacy and
            Terms of Use.
        </div>
      </div>
  </div>
</template>

<script>
import Footer from './Footer.vue'
export default {
  name: "Hero",
  components: {Footer},
  data: () => ({
    isMouseover: false,
  }),

  computed: {
    logo() {
      if (this.isMouseover) return "logo-y.png";
      else return "logo-w.png";
    },
  },
};
</script>

<style scoped>
ul{
  padding:0!important;
  text-align: center;
}
ul li {
  list-style: none;
  padding-top:1.5em;
}

ul li a{
  text-decoration: none;
  color:#fff;
  transition: all 0.5s;
}
ul li a:hover{
  color: #ffb757;
}
/* .hero {
  height: 100vh;
  overflow: hidden;
  border:1px solid blue;
} */
#gotoDigitalGreen{
  border:none;
  background-color:green;
  padding:1em 3em;
  outline:none;
  text-align: center;
  border-radius: 5px;
  color:#fff;
  text-decoration:none;
}
.hero{
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../assets/images/home.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  text-align: center;
}

.hero-text {
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin:0!important;
  padding-left: 0!important;
}
.hero-top{
  height:100vh!important;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction:column;
}
.hero__textLarge h1 {
  font-weight: bold;
  padding-bottom: 2rem;
}

.hero__textLarge h1 {
  font-size: 3rem;
}

.hero__textLarge h2 {
  font-size: 2.5rem;
}

.hero img {
  cursor: pointer;
}

.hero-footer{
  width: 100%;
  text-align: center;
  padding-bottom: 1em;
  background: #000;;
}
@media screen and (min-width: 768px) {
  ul li a{
    font-size: 2rem;
  }
  ul li {
  list-style: none;
  padding-top:1.2em;
}
}
</style>
