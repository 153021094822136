<template>
  <footer class="footer pb-2" id="partners">
    <div class="center">
      <h1 class="">Strategic Partners</h1>
      <b-row class="center footer-row mx-auto">
        <b-col md="4" lg="4">
          <div class="h-80">
            <img
              src="../assets/images/footer-img-1.png"
              alt="advert"
              style="width: 30%"
            />
          </div>
          <p>
            Supporting AfCFTA Implementation
          </p>
        </b-col>

        <b-col md="4" lg="4">
          <div class="h-80">
            <img
              src="../assets/images/footer-img-2.png"
              alt="advert"
              style="width: 30%"
            />
          </div>
          <p class="mt-4">
            Affiliated with the Sankoree Institute
          </p>
        </b-col>

        <b-col md="4" lg="4">
          <div class="h-80 mt-5">
            <img
              src="../assets/images/footer-img-3.png"
              alt="advert"
              style="width:50%"
            />
          </div>
          <p class="mt-5 ">
            In association with the AU Green Recovery Action Plan & Open
            Corridors Initiative
          </p>
        </b-col>
      </b-row>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
.footer {
  /* background: #000; */
  color: #f8f9fa;
}

.footer .center {
  text-align: center;
}

.footer-row {
  width: 90%;
}
.footer-text{
  opacity:0.4;
}
h1{
  font-size: 2em;
  padding-top:2em;
}
p{
  font-size: 1.1em;
}
</style>
