<template>
  <div  class="slides">
    <div class="slides__slide">

      <div class="slides__slideTopBar">
        <b-navbar-brand href="#">
          <img
            src="../assets/logo-w.png"
            alt="logo"
            style="max-width: 65px; height: auto"
          />
        </b-navbar-brand>
       <a
        pill
        size="md"
        style="text-decoration: none; color: green;"
        variant="link"
        class="ml-auto"
        href="http://green.4dcorridor.org"
        target="_blank"
        >Go Green <BIconArrowRight
      /></a>
      </div>

      <div class="slides__slideImage">
        <b-carousel
          ref="slides"
          v-model="slide"
          class="slides__carousel"
          img-width="1024"
          img-height="480"
          :interval="60000"
        >
          <b-carousel-slide
            v-for="(item, index) in brochureContentData"
            :key="index"
            class="slides__carouselSlide"
            :img-src="require('../assets/images/' + item.image)"
            ><div class="slides__carouselText">
              {{ item.caption[0] }}<br v-if="item.caption[1]" />{{
                item.caption[1] || ""
              }}
            </div></b-carousel-slide
          ></b-carousel
        >
      </div>

      <div class="slides__slideText">
        <div class="slides__slideInnerText pt-5">
            <a
              size="md"
              id="gogreen"
              class="mr-auto"
              href="http://green.4dcorridor.org"
              target="_blank"
              >Go Green
            </a>
          <b-button
            size="md"
            id="innertext-btn"
            variant="outline-dark"
            class="mb-2 ml-auto slides__btnSwitchScreen"
            @click="$emit('switchScreen')"
          >
            <BIconList />
          </b-button>
          <div
            v-for="(item, index) in brochureContentData[slide].body"
            :key="index"
          >
            <h1 v-if="item.heading" class="slides__slideHeading">{{ item.heading }}</h1>
            <p class="slides__text">{{ item.text }}</p>
            <div v-if="item.isJoin">
              <a :href="`${url()}/accounts/register`" style="float:right" target="_blank" class="join">Join 4D Now </a>
            </div>
          </div>

          <div class="slides__slideAction mt-5">
            <b-button
              pill
              variant="light"
              size="md"
              @click="next"
              class="mt-2 slides__btnNext mr-3"
              >Next Slide</b-button
            >
            <b-button
              variant="outline-light"
              pill
              size="md"
              style="text-decoration: none;"
              @click="prev"
              class="text-muted mt-2"
              v-if="slide > 0"
              ><BIconArrowLeft /> Previous Slide</b-button
            >
             
          </div>
        </div>
      </div>
    </div>
    <!-- small partners footer here -->
   
    <div class="slides__footer">
      <InnerPagesFooter />  
      <p class="text-muted" style="font-size: 1rem">Copyright &copy; 2021 4D Agenda. All rights reserved. Privacy and Terms of Use.</p>
    </div>
  </div>
</template>

<script>
import brochureContent from "./brochure.json";
import { BIconArrowLeft, BIconList, BIconArrowRight } from "bootstrap-vue";
import InnerPagesFooter from './InnerPagersFooter.vue'
import url from '@/helpers/url'
export default {
  name: "slides",
  components: { BIconArrowLeft, BIconList, BIconArrowRight, InnerPagesFooter},
  props:['current'],
  data: () => ({
    brochureContent,
    img: "about.jpg",
    image: "@/assets/images/about.jpg",
    slide: 0,
  }),
  computed: {
    brochureContentData() {
      let data = this.brochureContent
      
      if(this.current) {
        data[0] = this.brochureContent[this.current]
        return data;
      }
      if(this.current == 0 ) {
        return this.brochureContent
      }
      return data;
    }
  },
  methods: {
    prev() {
      this.$refs.slides.prev();
    },
    next() {
      this.$refs.slides.next();
    },
    url() {
        return url()
    },
  },
     mounted() {
      console.log('current');
    }

};
</script>

<style>
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  background: #111;
}
.slides__text{
  line-height: 2rem;
  font-size:1rem;
  font-weight: normal;
}
 #gogreen{
    background-color: green;
    padding:.5rem;
    border-radius:5px;
    color:#fff;
    position: fixed;
    text-decoration:none;
    outline:none;
    border:none;  
    top: 10px;
    left: 30px;
  }
.slides__slideHeading {
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 2rem;
}
.slides__btnNext {
  background: #ffa023 !important;
  color: white !important;
}

.slides__btnNext:hover {
  background: #ff960d !important;
  color: white !important;
}

.slides__slideAction {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.slides__footer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 62vw;
  bottom: 0;
}

.slides__slideImage {
  width: 100vw;
}

.slides__slideText {
  position: absolute;
  z-index: 3;
  width: 100vw;
  margin-top: -3rem;
}

.slides__btnSwitchScreen {
  position: fixed;
  top: 10px;
  right: 30px;
}

.slides__slideInnerText {
  margin: 0 auto;
  background-color: #fff;
  width: 80%;
  padding-top:3rem;
  padding-right: 6rem;
  padding-left:5rem;
  padding-bottom:3rem;
  height: 100vh;
  overflow-y: scroll;
}

.slides__carouselText {
  padding: 1rem;
  font-size: 5rem;
  z-index: 2;
  position: fixed;
  margin-top: -3rem;
  line-height: 90%;
  font-weight: bold;
}

.slides__carouselText {
  display: none;
}
.action {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width:500px) {
   .slides__slideInnerText {
    width:90%;
    padding:1rem .5rem;
  }
  .slides__slideHeading{
    font-size: 2rem;
  }
}
@media (max-width: 960px) {
  #innertext-btn{
    background:#000;
    color:#fff;
    font-weight: bold;
  }
  .slides__slideInnerText {
    height: auto;
    overflow: hidden;
    padding: 2rem;
    margin-bottom:10rem;
  }
  .slides__slideTopBar {
    display: none;
  }
  .slides__footer{
    text-align: center;
    margin-top:2em;
    width:100%;
  }
}

@media (min-width: 960px) {
  #gogreen{
    display:none;
  }
  .slides__slide {
    position: relative;
  }
  .slides__slideTopBar {
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    width: 60vw;
  }

  .slides__slideImage {
    width: 62vw;
    z-index: 2;
    position: fixed;
    top: 15vh;
    height: 70vh;
  }

  .slides__slideText {
    width: 40vw;
    right: 0;
    top: 0;
    margin: 0;
    z-index: 0;
    position: absolute;
    height: 100vh;
  }

  .slides__slideInnerText {
    width: 100%;
  }

  .slides__carousel {
    height: 100%;
    /* overflow: hidden; */
    width: 100%;
  }

  img {
    width: 100%;
    object-fit: contain;
  }
}
.join{
  background-color:green;
  color:#fff;
  border:none;
  outline:none;
  text-decoration:none;
  padding:.5em;
  border-radius: 5px;
  transition:all 0.3s;
}
.join:hover{
  text-decoration:none;
  color:#fff;
  opacity:.9;
}
#innertext-btn{
  border:1px solid #000;
}
@media screen and (max-width:768px) {
  .slides__slideInnerText {
    margin-bottom:30rem;
  }
}
@media screen and (max-width:500px) {
  .slides__slideInnerText {
    margin-bottom:21rem;
  }
}
</style>
