<template>
  <Hero v-if="!showSlides" @switchScreen="getCurrent" />
  <div fluid v-else>
    <template>
      <Slides :current="current" @switchScreen="showSlides = false" />
    </template>
  </div>
</template>

<script>
import Slides from "./components/Slides.vue";
import Hero from "./components/Hero.vue";

export default {
  name: "App",

  components: { Slides, Hero },

  data: () => ({
    showSlides: false,
    current:''
  }),

  methods: {
    getCurrent(value) {
      this.showSlides = true
      this.current = value;
      console.log('value',value)
    }
  }
};
</script>

<style>
@import "assets/_custom.scss";
  @font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI"),
   url('../src/assets/Segoe-UI-Font/SEGOEUI.TTF') format("truetype");
}
#app {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-family: "Segoe UI", sans-serif;
  /* font-family: "Open Sans", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}
</style>
