<template>
  <footer class="footer pb-4" id="partners">
    <div class="">
      <b-row class="footer-row center">
        <b-col>
            <b-row class="center">
                <b-col md="3" lg="3">
                    <div class="h-30">
                        <img
                        src="../assets/images/footer-img-1.png"
                        alt="advert"
                        style="width: 20%"
                        />
                    </div>
                    </b-col>

                    <b-col md="3" lg="3">
                    <div class="h-30">
                        <img
                        src="../assets/images/footer-img-2.png"
                        alt="advert"
                        style="width: 20%"
                        />
                    </div>
                    </b-col>
                    <b-col md="3" lg="3">
                    <div class="h-30 mt-3">
                        <img
                        src="../assets/images/footer-img-3.png"
                        alt="advert"
                        style="width:40%"
                        />
                    </div>
                </b-col>
            </b-row>
        </b-col>
      </b-row>
    </div>
  </footer>
</template>

<script>
export default {
  name: "innerpagesfooter",
};
</script>

<style>
.footer {
  /* background: #000; */
  color: #f8f9fa;
}

.footer .center {
  text-align: center;
}

.footer-row {
  width: 90%;
}
.footer-text{
  opacity:0.4;
}
h1{
  font-size: 2em;
  padding-top:2em;
}
p{
  font-size: 1.1em;
}
</style>
